import { cls, formatDurationAbbr, openNewTab, whisper } from '@/utils'
import IconYes from '@/public/assets/yes.svg'
import IconDiamond from '@/public/assets/diamond-2.svg'
import Button from '../button'
import IconX from '@haiper/icons-svg/icons/band/x.svg'
import IconTikTok from '@haiper/icons-svg/icons/band/tiktok.svg'
import IconCalendar from '@haiper/icons-svg/icons/outline/calendar.svg'
import { useRouter } from 'next/navigation'
import { useCallback, useMemo } from 'react'
import { X_LINK } from '@/constants'
import useCheckInStreak from '@/hooks/useCheckInStreak'
import IconClockTimeHistory from '@haiper/icons-svg/icons/outline/clock-time-history.svg'
import dayjs from 'dayjs'
import { useCachedVisibleMarketingEvents } from '@/hooks/useVisibleMarketingEvents'

const showDailyCreationChanllenge = false
const showShareToSocialMedia = false

const regexpPromptOfTheWeek = /prompt\s+of\s+the\s+week/i
export interface WinMoreProps {
  className?: string
  onClose?: () => void
}

export default function WinMore({ className, onClose }: WinMoreProps) {
  const {
    dailyCreationCount,
    dailyCreationGoalReached,
    dailyCreationGoal,
    dailyCreationRewardCollected,
    dailyCreationReward,
    collectDailyCreationReward,
    nextDailyCreationRewardTime,
  } = useCheckInStreak()

  const listItemClassname = 'w-full md:h-16 py-2 flex justify-between items-center gap-2'
  const iconClassname = 'size-6 shrink-0 grow-0 flex items-center justify-center text-icon-subdued'
  const descriptionClassname = 'flex-1 flex items-center text-body-md tracking-15'
  const buttonClassname = 'w-24 md:w-30 h-10 ml-auto'

  const router = useRouter()

  const { data: marketingEvents } = useCachedVisibleMarketingEvents()
  const promotOfTheWeekEvent = useMemo(
    () => marketingEvents?.find((e) => regexpPromptOfTheWeek.test(e.title) || regexpPromptOfTheWeek.test(e.abstract)),
    [marketingEvents],
  )

  const gotoSubmit = useCallback(() => {
    router.push('/submit?type=spotlight')
    onClose?.()
  }, [router, onClose])

  const gotoCreations = useCallback(() => {
    router.push('/creations')
    onClose?.()
  }, [router, onClose])

  const gotoX = useCallback(() => {
    openNewTab(X_LINK)
    onClose?.()
  }, [onClose])

  const gotoEvents = useCallback(() => {
    if (promotOfTheWeekEvent) {
      router.push(`/event?evt=${promotOfTheWeekEvent.event_id}`)
    } else {
      router.push('/events')
    }
    onClose?.()
  }, [router, onClose, promotOfTheWeekEvent])

  return (
    <div className={cls('p-4 flex flex-col w-full', className)}>
      <div className='w-full text-heading-lg font-bold tracking-45'>Win more credits</div>
      {showDailyCreationChanllenge && (
        <div className={listItemClassname} aria-label='action/creation'>
          <div className={iconClassname} aria-label='icon'>
            {dailyCreationGoalReached ? (
              <IconYes className='size-5 shrink-0 grow-0' />
            ) : (
              <div className='size-2 rounded-full bg-surface-active' />
            )}
          </div>
          <div className={cls(descriptionClassname, 'flex flex-row gap-2')} aria-label='description'>
            <span>Complete one creation</span>
            <div className='px-2 py-0.5 bg-surface-subdued rounded-md text-text font-bold'>
              {dailyCreationCount}
              <span className='text-text-subdued px-1'>/</span>
              {dailyCreationGoal}
            </div>
            <div className='border px-2 py-0.5 text-text font-bold flex gap-1 items-center rounded-md'>
              <IconDiamond className='size-4 shrink-0 grow-0' />
              <span>{dailyCreationReward}</span>
            </div>
          </div>
          {dailyCreationGoalReached ? (
            <Button
              variant='outline'
              className={buttonClassname}
              aria-label='action'
              disabled={dailyCreationRewardCollected}
              onClick={collectDailyCreationReward}
            >
              {dailyCreationRewardCollected ? (
                <div className='flex items-center gap-1'>
                  <IconClockTimeHistory className='size-6 shrink-0 grow-0' />
                  <span>Refresh in {formatDurationAbbr(dayjs(), nextDailyCreationRewardTime)}</span>
                </div>
              ) : (
                'Collect reward'
              )}
            </Button>
          ) : (
            <Button variant='primary' className={buttonClassname} aria-label='action' onClick={gotoCreations}>
              Start creating
            </Button>
          )}
        </div>
      )}
      <div className={listItemClassname} aria-label='action/follow'>
        <IconX className={iconClassname} />
        <div className={cls(descriptionClassname, 'flex flex-col items-start')} aria-label='description'>
          <div>Follow us on X (Twitter)</div>
          <span className='text-body-sm text-text-subdued'>Our new Twitter followers have a chance to win credit</span>
        </div>
        <Button variant='primary' className={buttonClassname} aria-label='action' onClick={gotoX}>
          Go to X
        </Button>
      </div>
      {showShareToSocialMedia && (
        <div className={listItemClassname} aria-label='action/share'>
          <IconTikTok className={iconClassname} />
          <div className={descriptionClassname} aria-label='description'>
            Share your creations to social media
          </div>
          <Button variant='outline' className={buttonClassname} aria-label='action' onClick={gotoSubmit}>
            Detail
          </Button>
        </div>
      )}
      <div className={listItemClassname} aria-label='action/event'>
        <IconCalendar className={iconClassname} />
        <div className={descriptionClassname} aria-label='description'>
          Join the Prompt of the Week
        </div>
        <Button variant='outline' className={buttonClassname} aria-label='action' onClick={gotoEvents}>
          Detail
        </Button>
      </div>
    </div>
  )
}
