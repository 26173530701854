import type { CheckInConfig, CheckInStatus } from '@/types'
import { rest } from '@/utils'

export const getCheckInStatus = async (): Promise<CheckInStatus> => {
  const res = await rest.get('/v1/checkin/info')
  return res.data
}

export const checkIn = async () => {
  const res = await rest.post('/v1/checkin')
  return res.data
}

export const getCheckInConfig = async (): Promise<CheckInConfig> => {
  const res = await rest.get('/v1/checkin/config')
  return res.data
}
