import { CheckInStatus, PoNVoid } from '@/types'
import utcPlugin from 'dayjs/plugin/utc'
import dayjs, { Dayjs, utc } from 'dayjs'
import { useCachedCheckInStatus } from './useCheckInStatus'
import { useAtom } from 'jotai'
import { checkInStatusAtom } from '@/atoms'
import { useCallback, useEffect, useMemo } from 'react'
import { todo, whisper } from '@/utils'
import { checkIn as remoteCheckIn } from '@/service/checkin.service'

dayjs.extend(utcPlugin)

export interface UseCheckInStreakResp {
  currentCheckInStreak: number // days
  lastCheckInStreak: number // days, should be (currentCheckInStreak - 1) or currentCheckInStreak
  checkIn: () => PoNVoid
  lastCheckInTime: Dayjs | null
  nextCheckInTime: Dayjs

  dailyCreationCount: number
  dailyCreationGoal: number
  dailyCreationReward: number
  dailyCreationGoalReached: boolean
  dailyCreationRewardCollected: boolean
  collectDailyCreationReward: () => PoNVoid
  nextDailyCreationRewardTime: Dayjs

  loading: boolean
  refresh: () => PoNVoid
}

export default function useCheckInStreak(): UseCheckInStreakResp {
  const now = dayjs.utc()
  const [checkInStatus, setCheckInStatus] = useAtom(checkInStatusAtom)
  const { data: remoteCheckInStatus, isValidating: loading, refresh } = useCachedCheckInStatus()

  useEffect(() => {
    if (remoteCheckInStatus) {
      setCheckInStatus(remoteCheckInStatus)
    }
  }, [remoteCheckInStatus, setCheckInStatus])

  const isCheckInStreakValid = useMemo(() => {
    // both first and last check-in time should be valid date
    if (!checkInStatus?.last_checkin_time) {
      return false
    }
    const last = dayjs.utc(checkInStatus.last_checkin_time)
    if (!last.isValid()) {
      return false
    }

    // last check-in time should be today or yesterday
    if (last.isAfter(now, 'day')) {
      return false
    }
    if (last.isBefore(now.subtract(1, 'day'), 'day')) {
      return false
    }

    return true
  }, [checkInStatus, now])

  const lastCheckInTime = useMemo(
    () =>
      isCheckInStreakValid && checkInStatus?.last_checkin_time ? dayjs.utc(checkInStatus.last_checkin_time) : null,
    [checkInStatus, isCheckInStreakValid],
  )

  const lastCheckInStreak = useMemo(() => {
    return checkInStatus?.checkin_streak ?? 0
  }, [checkInStatus])

  const firstCheckInTime = useMemo(() => {
    if (!lastCheckInTime || !lastCheckInStreak) {
      return null
    }
    return lastCheckInTime.subtract(lastCheckInStreak - 1, 'day').startOf('day')
  }, [lastCheckInTime, lastCheckInStreak])

  const currentCheckInStreak = useMemo(() => {
    if (!firstCheckInTime) {
      return 1
    }
    const result = Math.max(now.diff(firstCheckInTime, 'day') + 1, lastCheckInStreak)
    return result > 7 ? 1 : result
  }, [now, firstCheckInTime, lastCheckInStreak])

  const checkIn = useCallback(async () => {
    await remoteCheckIn()
    setCheckInStatus((prev: CheckInStatus | null) => {
      if (!prev) {
        return {
          checkin_streak: 1,
          can_checkin: false,
          last_checkin_time: dayjs.utc().toISOString(),
        }
      }
      return {
        ...prev,
        checkin_streak: (prev.checkin_streak ?? 0) + 1,
        can_checkin: false,
        last_checkin_time: dayjs.utc().toISOString(),
      }
    })
    refresh()
  }, [setCheckInStatus, refresh])

  const nextCheckInTime = useMemo(() => {
    // today or tomorrow
    if (currentCheckInStreak === lastCheckInStreak) {
      return now.add(1, 'day').startOf('day')
    }
    return now.startOf('day')
  }, [now, currentCheckInStreak, lastCheckInStreak])

  const dailyCreationGoal = 1
  const dailyCreationReward = 50 // 50 credits
  const dailyCreationCount = checkInStatus?.creation_count ?? 0
  const dailyCreationGoalReached = dailyCreationCount >= dailyCreationGoal

  const dailyCreationRewardCollected = useMemo(() => {
    if (!checkInStatus?.last_creation_reward_time) {
      return false
    }
    const lastCreationRewardTime = dayjs.utc(checkInStatus.last_creation_reward_time)
    return lastCreationRewardTime.isSame(now, 'day')
  }, [checkInStatus, now])

  const nextDailyCreationRewardTime = useMemo(() => {
    // today or tomorrow
    if (dailyCreationRewardCollected) {
      return now.add(1, 'day').startOf('day')
    }
    return now.startOf('day')
  }, [now, dailyCreationRewardCollected])

  const collectDailyCreationReward = useCallback(async () => {
    todo()
  }, [])

  return {
    currentCheckInStreak,
    lastCheckInStreak,
    lastCheckInTime,
    nextCheckInTime,
    dailyCreationCount: Math.min(dailyCreationCount, dailyCreationGoal),
    dailyCreationGoal,
    dailyCreationReward,
    dailyCreationGoalReached,
    dailyCreationRewardCollected,
    nextDailyCreationRewardTime,
    checkIn,
    collectDailyCreationReward,
    loading,
    refresh,
  }
}
